<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="8"
      >
        <b-card
          :title="$t('Create New Offense')"
        >
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.fee"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('fee')"
                  :state="errors.fee ?false:null"
                  :class="errors.fee ?'is-invalid':null"
                />
                <small
                  v-if="errors.fee"
                  class="text-danger"
                >{{ errors.fee[0] }}</small>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('checkk number') }}</label>
                <b-form-input
                  v-model="data.checkk_id"
                  class="mt-2"
                  :state="errors.checkk_id ?false:null"
                  :placeholder="$t('checkk number')"
                />
                <small
                  v-if="errors.checkk_id"
                  class="text-danger"
                >{{ errors.checkk_id[0] }}</small>
                <label>{{ $t('checkk number') }}</label>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('notification') }}</label>
                <b-form-textarea
                  v-model="data.notification"
                  class="mt-2"
                  :state="errors.notification ?false:null"
                  :placeholder="$t('notification')"
                />
                <small
                  v-if="errors.notification"
                  class="text-danger"
                >{{ errors.notification[0] }}</small>
                <label>{{ $t('notification') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
            >
              <b-button
                v-for="el in [3,6,9,12]"
                :key="el"
                class="m-1"
                variant="primary"
                @click="ExpirationDateAfter(el)"
              >
                {{ $t('after') }} {{ el }} {{ $t('month') }}
              </b-button>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div>
                <label>{{ $t('Expiration_date') }}</label>
                <b-form-datepicker
                  v-model="data.expiration_date"
                  :placeholder="$t('Expiration_date')"
                  :state="errors.expiration_date ?false:null"
                />
                {{ expiration_date }}
                <small
                  v-if="errors.expiration_date"
                  class="text-danger"
                >{{ errors.expiration_date[0] }}</small>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('payed status') }}</label>
                <b-form-checkbox
                  v-model="data.payed"
                  :value="true"
                  :state="errors.payed ?false:null"
                >
                  {{ data.payed ? $t('payed') : $t('Not Payed') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.payed"
                class="text-danger"
              >{{ errors.payed[0] }}</small>
            </b-col>

          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-row>

          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-button
                    class="w-100 mb-1"
                    variant="gradient-success"
                    @click="create"
                  >
                    {{ $t('Create') }}
                  </b-button>
                  <b-button
                    class="w-100"
                    variant="gradient-danger"
                    @click="data={},errors=[]"
                  >
                    {{ $t('Reset Form') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>

    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {
        date_time: `${this.moment().format('YYYY-MM-DD')}`,
        expiration_date: `${this.moment().add(3, 'months').format('YYYY-MM-DD')}`,
      },
      errors: [],
      fileErrors: '',

    }
  },
  computed: {
    types() {
      return [{ key: 'identify', title: this.$t('identifyType') }, { key: 'passport', title: this.$t('passport') }, { key: 'other', title: this.$t('other') }]
    },
  },
  created() {
    this.data.expiration_date = this.moment().add(3, 'months').format('YYYY-MM-DD')
  },
  methods: {

    ExpirationDateAfter(months) {
      this.data.expiration_date = this.moment().add(months, 'months').format('YYYY-MM-DD')
    },
    setBoolean(val) {
      if (this.data[val] === undefined) { this.data[val] = false }
    },
    resetData() {
      this.data = {
        wheel: true,
        owwner: null,
        expiration_date: `${this.moment().add(3, 'months').format('YYYY-MM-DD')}`,
        date_time: `${this.moment().format('YYYY-MM-DD')}`,
      }
    },
    UpdateFileError(variable) {
      this.fileErrors = variable
    },
    create() {
      this.errors = []
      this.$store.dispatch('offense/Create', this.data).then(() => {
        this.data = {}
        this.resetData()
      }).catch(error => {
        this.errors = error.response.data.errors
      })
    },
  },
}
</script>
